import { mkNode } from './utils';
import { Img } from './image-base';
import { Thumbnail, ThumbnailBase } from './thumbnail-base';

export class ResourceAudio extends ThumbnailBase implements Thumbnail {
    private audio: HTMLAudioElement;
    private rows: number;

    public constructor(image: Img, frag: Node, releaseButton = false) {
        const rows = 120;
        let audio;
        super(frag, image, releaseButton, () => {
            audio = mkNode('audio', {
                className: 'thumbnail',
                parent: this.figure,
                style: {visibility: 'hidden', height: rows + 'px'},
                attrib: {controls: 'true'}
            });
        });
        this.rows = rows;
        if (!audio) {
            throw "Thumbnail audio is null";
        }
        this.audio = audio;
    }

    public async load(buffers: ArrayBuffer|null): Promise<void> {
        const mime = this.image.mime;
        if (buffers) { // } && mime) {
            const blob = new Blob([buffers], {type: mime});
            this.audio.src = URL.createObjectURL(blob);
        } else {
            console.error('video steam null');
        }
        this.audio.style.visibility = 'visible';
    }

    public async open(): Promise<void> {
        return;
    } 
    
    public destroy(): void {
        super.destroy();
        if (this.audio.src) {
            console.log('REVOKE AUDIO URL');
            URL.revokeObjectURL(this.audio.src);
            this.audio.src = '';
        }
    }
}
