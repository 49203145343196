import { DicomViewer } from 'image-viewer';
import { mkNode, scrollRangeIntoView, removeNode, removeChildren } from 'utils';
import { Img } from 'image-base';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from 'question-base';


export interface LightboxContext {
    fullscreenParent: Element;
    scrollContainer: Element;
    getImageBegin(): Promise<void>;
    getImageFrame(image: Img, frame: number): Promise<ArrayBuffer>;
    getImageEnd(): Promise<void>;
    getNavigating(): boolean;
    //noMouse: boolean,
    meta: PractiqueNet.ExamJson.Definitions.ExamMeta,
}

export class Lightbox {
    private context: LightboxContext;
    private viewers = new Map<number, DicomViewer>();
    private lightboxPanel: HTMLDivElement;
    private gridButton: HTMLButtonElement;
    private buttonText: HTMLSpanElement;
    private controlPanel: ControlPanel;
    private gridControlPanel: HTMLElement;
    private gridPanel?: HTMLDivElement;

    private rows = 1;
    private cols = 1;
    private slots: HTMLElement[] = [];
    private next = 0;
    private readonly gridSizes = ['1x1', '2x2'];
    private readonly sizeMap: {[index: string]: {width: number, height: number}} = {
        '1x1': {width: 1, height: 1},
        '2x2': {width: 2, height: 2},
    };
    private gridIndex = 0;

    private openGridPanel() {
        if (!this.gridPanel) {
            this.gridPanel = mkNode('div', {parent: this.gridControlPanel, className: 'meetingBar'});
            for (const gridSize of this.gridSizes) {
                mkNode('button', {
                    parent: this.gridPanel,
                    attrib: {'data-size': gridSize},
                    className: 'app-button config-primary-hover',
                    children: [
                        mkNode('span', {children: [
                            mkNode('text', {text: gridSize})
                        ]})
                    ]
                });
            }
            this.gridPanel.addEventListener('click', this.handleGridSelect);
        }
    }

    private closeGridPanel() {
        if (this.gridPanel) {
            this.gridPanel.removeEventListener('click', this.handleGridSelect);
            removeNode(this.gridPanel);
            this.gridPanel = undefined;
        }
    }

    private readonly handleGridButton = async () => {
        if (this.gridSizes.length < 3) {
            this.gridIndex = (this.gridIndex + 1) % this.gridSizes.length;
            const name = this.gridSizes[this.gridIndex];
            ({width: this.cols, height: this.rows} = this.sizeMap[name]);
            this.buttonText.textContent = name;
            await this.updateGrid();
        } else {
            if (this.gridPanel) {
                this.openGridPanel();
            } else {
                this.closeGridPanel();
            }
        }
    }

    private readonly handleGridSelect = async (event: Event): Promise<void> => {
        if (this.gridPanel) {
            let elem = event.target;
            while (elem instanceof HTMLElement && !elem.dataset['size']) {
                elem = elem.parentElement;
            }
            const name = elem instanceof HTMLElement && elem.dataset['size'];
            if (name && name in this.gridSizes) {
                const size = this.sizeMap[name];
                this.cols = size.width;
                this.rows = size.height;
                this.buttonText.textContent = name;
            }
            this.closeGridPanel();
            await this.updateGrid();
        }
    }

    private async updateGrid(): Promise<void> {
        if (this.viewers.size > 0) {
            await this.closeAll();
        }
        removeChildren(this.lightboxPanel);
        this.slots = new Array(this.rows * this.cols);
        const xstep = 100.0 / this.cols;
        const ystep = 100.0 / this.rows;
        let k = 0;
        let y0 = 0;
        let y1 = 100;
        for(let j = 0; j < this.rows; ++j) {
            y1 -= ystep;
            let x0 = 0;
            let x1 = 100; 
            for (let i = 0; i < this.cols; ++i) {
                x1 -= xstep;
                this.slots[k++] = mkNode('div', {parent: this.lightboxPanel, style: {
                    display: 'flex', 
                    flexDirection: 'column', 
                    position: 'absolute', 
                    left: x0.toString() + '%', 
                    top: y0.toString() + '%', 
                    right: x1.toString() + '%', 
                    bottom: y1.toString() + '%'
                }});
                x0 += xstep;
            }
            y0 += ystep;
        }
        this.next = 0;
    }
   
    constructor(context: LightboxContext, controlPanel: ControlPanel, gridControlPanel: HTMLElement, parent: Node) {
        this.context = context;
        this.controlPanel = controlPanel;
        this.gridControlPanel = gridControlPanel;
        this.lightboxPanel = mkNode('div', {className: 'lightbox-panel', parent: parent, attrib: {'aria-hidden': 'true'}});
        this.buttonText = mkNode('span', {className: 'app-button-text', children: [
            mkNode('text', {text: '1x1'}),
        ]});
        this.gridButton = mkNode('button', {
            className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
            //attrib: {disabled: 'true'},
            children: [
                mkNode('icon', {icon: faThLarge}),
                this.buttonText,
            ]
        });
        if (!context.meta.disableDicomGrid) {
            this.controlPanel.add(this.gridButton)
        }
        this.gridButton.addEventListener('click', this.handleGridButton);
        this.updateGrid();
    }

    public async destroy(): Promise<void> {
        if (this.viewers.size > 0) {
            await this.closeAll();
        }
        this.closeGridPanel();
    }

    public async closeAll(): Promise<void> {
        for (let i = 0; i < this.slots.length; ++i) {
            const viewer = this.viewers.get(i);
            if (viewer) {
                await viewer.setDicom();
                await viewer.destroy(); // callback will delete map entry & hide lightboxPanel
            }
        }
    }

    public async close(id: string): Promise<void> {
        for (let i = 0; i < this.slots.length; ++i) {
            const viewer = this.viewers.get(i);
            if (viewer && (id === undefined || id === viewer.getRenderer()?.img.id)) {
                await viewer.setDicom();
                await viewer.destroy(); // callback will delete map entry & hide lightboxPanel
            }
        }
    }

    public setHeight(height: number): void {
        this.lightboxPanel.style.height = height + 'px';
    }

    public setVisible(visible: boolean): void {
        this.lightboxPanel.setAttribute('aria-hidden', visible ? 'false' : 'true');
        this.lightboxPanel.dispatchEvent(new CustomEvent('visibility', {
            bubbles: true,
            detail: {
                visibility: visible,
            }
        }));
    }

    private newViewer(x: number): DicomViewer {
        const viewer = new DicomViewer({
            parent: this.slots[x],
            scrollContainer: this.slots[x],
            fullscreenParent: this.context.fullscreenParent,
            after: null,
            sizeReference: this.lightboxPanel,
            getImageBegin: this.context.getImageBegin,
            getImageFrame: async (image: Img, frame: number): Promise<ArrayBuffer> => {
                const data = await this.context.getImageFrame(image, frame);
                //console.log('THUMB GOT FRAME');
                return data;
            },
            getImageEnd: this.context.getImageEnd,
            getNavigating: this.context.getNavigating,
            //noMouse: this.context.noMouse,
            window: window,
            forceFullscreen: false, // isTouchDevice,
        });
        viewer.onclose = (): void => {
            //thumbnail.select(false);
            scrollRangeIntoView(this.lightboxPanel);
            this.viewers.delete(x);
            console.log('CLOSE', x, this.viewers.size);
            if (this.viewers.size === 0) {
                this.setVisible(false);
            }
        };
        this.viewers.set(x, viewer);
        return viewer;
    }

    public disabled(isDisabled: boolean): void {
        this.viewers.forEach(viewer => {
            viewer.disabled(isDisabled);
        })
        this.gridButton.disabled = isDisabled;
        if (isDisabled) {
            this.closeGridPanel();
            this.closeAll();
        }
    }

    public open(): DicomViewer {
        for (let i = 0; i < this.slots.length; ++i) {
            if (!this.viewers.get(i)) {
                this.next = i;
                break;
            }
        }
        if (this.next >= this.slots.length) {
            this.next = 0
        }
        if (!this.viewers.has(this.next)) {
            const viewer = this.newViewer(this.next++);
            this.setVisible(true);
            return viewer;
        } else {
            const viewer = this.viewers.get(this.next++);
            if (!viewer) {
                throw new Error('Viewer is null');
            }
            return viewer;
        }
    }
}
