import { faListOl, faSignOutAlt, faCalculator, /*faUser, faClock,*/ faArrowLeft, faArrowRight, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { removeChildren, mkNode, scrollRangeIntoView, safeJsonParse, isIndexed } from "utils";
import { QuestionViewer, evalExprBool } from "question-viewer";
import * as math from 'mathjs-expression-parser';
import { AnswerKey, AnswerValue, Expr } from 'question-base';
import { Img } from 'image-base';
import { ResponseModel, ResponseStatus, Structure, LocalData, RemoteData } from 'exam-service';
//import {isTouchDevice, hasMouse} from './utils-device';
import { examCleanup, urlWithCredentials } from 'utils-net';
import { dbGet, dbPut, dbClearSelect } from 'utils-db';
import { pinToKey } from 'utils-zip';
import { Modal } from 'utils-progress';
import { translate } from 'utils-lang';
import { ReconnectingEventSource } from 'utils-events';
import { RobotCat } from 'robot-cat';
import { Accessibility } from 'exam-accessibility';
import { ExamTimer, isSchedule, VersionedSchedule, isVersionedSchedule } from 'exam-timer';
import ResizeObserver from 'resize-observer-polyfill';
import { ConnectionStatus } from './meeting';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface EventSourceEventMap {
        'exam-pending': MessageEvent;
        'exam-started': MessageEvent;
        'exam-paused': MessageEvent;
        'exam-resumed': MessageEvent;
        'exam-stopped': MessageEvent;
        'exam-deleted': MessageEvent;
        'exam-time': MessageEvent;
    }
}

interface Storage {
    getImageBegin(): Promise<void>;
    getImageFrame(image: Img, frame: number): Promise<Uint8Array>;
    getImageEnd(): Promise<void>;
}

interface ExamCxt {
    content: HTMLElement;
    structure: Structure[];
    imageStore: Storage;
    //examId: string;
    //showQuestionTitle: boolean;
    //demo: boolean;
    //getStatus: (examId: string, candidateId: string) => Promise<StateData[]>;
    //loadFlags: () => Promise<Flags[]>;
    //storeFlag: (qno: number, ano: number, flag: boolean) => Promise<void>;
    //noMouse: boolean;
    //loadAnswer: (args: BackendId) => Promise<SaveData>;
    candidateId: string;
    //order: number[];
    factorDetails?: {[ix: string]: PractiqueNet.ExamJson.Definitions.UserDetails};
    //enableCopyPaste: boolean;
    //disableResourceLocking: boolean;
    meta: PractiqueNet.ExamJson.Definitions.ExamMeta;
    onDestroy: () => Promise<void>;
}

interface ExamContext extends ExamCxt {
    responses: ResponseModel;
    onDestroy: () => Promise<void>;
}

class Overview {
    private state = ResponseStatus.emptyRemote;
    questionReview: HTMLDivElement;
    summaryText: Text;
    flag: HTMLSpanElement;

    qid: number;
    aid: number;
    backendQid: number;
    backendAid: number;
    backendFid?: string;
    displayId?: string;
    //fullNumber?: string;
    visible?: Expr;
    flagged: boolean;
    showOverview: boolean;
    navigateTo: (i: number, j: number) => Promise<void>;

    constructor(
        structure: Structure,
        qid: number,
        aid: number,
        navigateTo: (i: number, j: number) => Promise<void>
    ) {
        this.questionReview = mkNode('div', { className: 'question-review config-background config-background-hover' });
        this.summaryText = mkNode('text', { parent: this.questionReview });
        this.flag = mkNode('span', {
            parent: this.questionReview,
            attrib: { style: 'float:right;' },
            children: [
                mkNode('text', { text: '\u2691' }),
            ],
        });
        this.backendQid = structure.backendQid[0];
        this.backendAid = structure.backendAid[aid];
        this.backendFid = structure.factor;
        this.qid = qid;
        this.aid = aid;
        this.visible = structure.visible[aid];
        this.navigateTo = navigateTo;

        //let text = '';
        //if (structure.backendAid.length > 1) {
        //    text = (qid + 1) + '.' + (aid + 1);
        //} else {
        //   text = (qid + 1).toString();
        //}
        let text = '';
        if (structure.factor !== undefined) {
            text += structure.factor;
        } else {
            text += (qid + 1);
        }
        if (structure.displayNumber[aid] !== undefined) {
            text += ' '.repeat((structure.indents?.[aid] ?? 0) + 1) + structure.displayNumber[aid];
            this.displayId = structure.displayNumber[aid]?.trim();
        } else if (structure.backendAid.length > 1) {
            let x = 0;
            for(let i = 0; i < aid; ++i) {
                if (structure.answerType[aid] !== 'label') {
                    ++x;
                }
            }
            text += '.' + (x + 1);
            if (structure.answerType[aid] !== 'label') {
                this.displayId = (x + 1).toString();
            }
        }
        /*if (structure.fullNumber && structure.fullNumber[aid]) {
            this.fullNumber = structure.fullNumber[aid];
            text += this.fullNumber;
        }
        if (structure.shortNumber && structure.shortNumber[aid]) {
            this.displayId = structure.shortNumber[aid];
        }
        if (text.length == 0) {
            text += '-';
        }*/
        this.showOverview = structure.answerType[aid] !== 'label';

        this.summaryText.textContent = text;
        this.flag.style.visibility = 'hidden';
        this.flagged = false;
    }

    async select(): Promise<void> {
        this.questionReview.setAttribute('aria-pressed', 'true');
        await this.navigateTo(this.qid, this.aid);
        this.questionReview.setAttribute('aria-pressed', 'false');
    }

    setVisible(vis: boolean): void {
        this.questionReview.style.display = (this.showOverview && vis) ? 'block' : 'none';
    }

    setStatus(state: ResponseStatus): void {
        this.state = state;
        switch (state) {
            case ResponseStatus.emptyLocal:
                this.questionReview.className = 'question-review config-background config-background-hover';
                break;
            case ResponseStatus.savedLocal:
                this.questionReview.className = 'question-review config-warn config-warn-hover';
                break;
            case ResponseStatus.emptyRemote:
                this.questionReview.className = 'question-review config-background config-background-hover';
                break;
            case ResponseStatus.savedRemote:
                this.questionReview.className = 'question-review config-safe config-safe-hover';
                break;
        }
    }

    getStatus(): ResponseStatus {
        return this.state;
    }
}

interface TimingMessage {
    timestamp: number;
    users: string[];
    schedule: PractiqueNet.ExamJson.Definitions.Schedule;
    scheduleVersion: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTimingMessage(x: any): x is TimingMessage {
    if (!isIndexed(x)) {
        console.warn('TimingMessage is not an object');
        return false;
    }
    if (!isSchedule(x.schedule)) {
        console.warn('TimingMessage has invalid or missing Schedule');
        return false;
    }
    if (!Array.isArray(x.users)) {
        console.warn('TimingMessage has invalid or missing users list');
    }
    if (typeof x.scheduleVersion !== 'number') {
        console.warn('TimingMessage has invalid or missing scheduleVersion');
        return false;
    }
    if (typeof x.timestamp !== 'number') {
        console.warn('TimingMessage has invalid or missing timestamp');
        return false;
    }
    return true;
}

interface ControlMessage {
    timestamp: number;
    users: string[];
    pin?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isControlMessage(x: any): x is ControlMessage {
    return x && typeof x === 'object' &&
        typeof x.timestamp === 'number' &&
        (typeof x.pin === 'undefined' || typeof x.pin === 'string') &&
        (typeof x.users === 'undefined' || Array.isArray(x.users));
}

const appButtonClasses = 'app-button config-primary-hover config-primary-fg-shadow-focus';

class ExamViewer {
    private contentPanel: HTMLDivElement;
    private toolBar: HTMLDivElement;
    private statusBar: HTMLDivElement;
    private languageBar: HTMLDivElement;
    private calculatorBar: HTMLDivElement;
    private meetingBar: HTMLDivElement;
    //private controlBar: HTMLDivElement;
    private notificationArea: HTMLDivElement;
    private questionReview: HTMLButtonElement;
    private langControl: HTMLButtonElement;
    private calcControl: HTMLButtonElement;
    private logoutControl: HTMLButtonElement;
    //private appTime: HTMLButtonElement;
    //private appTimeSpan: HTMLSpanElement;
    //private time: Text;
    //private appVersion: HTMLButtonElement;
    private appCid: HTMLButtonElement;
    private cid: HTMLSpanElement;
    //private space: HTMLDivElement;
    private navControls: HTMLDivElement;
    private prevControl: HTMLButtonElement;
    private nextControl: HTMLButtonElement;
    private hPanel: HTMLDivElement;
    private reviewPanel: HTMLDivElement;
    private reviewInner: HTMLDivElement;
    private examPanel: HTMLDivElement;
    //private logoutBackground: HTMLDivElement;
    //private logoutPanel: HTMLDivElement;
    //private logoutTitle: HTMLDivElement;
    //private logoutText: HTMLDivElement;
    //private logoutCancel: HTMLButtonElement;
    //private logoutOkay: HTMLButtonElement;
    private logoutModal: Modal;
    private calcText: HTMLInputElement;
    private calcEq: HTMLInputElement;
    private questionUi: QuestionViewer;

    private qid: number;
    private aid: number | null = null;
    private noPrev: boolean;
    private noNext: boolean;
    private isInvalid: boolean;
    private isNavigating: boolean;
    private isForced = false;
    //private isLocked = false;
    private overview: Overview[][];
    //private clockInterval: number;
    private structure: Structure[];
    private demo: boolean;
    private currentLanguage: number;
    //private answer_aes_key: string;
    private candidateId: string;
    private examId: string;

    private onDestroy: () => Promise<void>;
    private responses: ResponseModel;
    //private loadAnswer: (args: BackendId) => Promise<SaveData>;

    private eventSource?: ReconnectingEventSource;
    private examTimer: ExamTimer;
    private accessibility?: Accessibility;
    private robotCat?: RobotCat;
    private resizeObserver: ResizeObserver;
    private timing: PractiqueNet.ExamJson.Definitions.Timing | undefined;

    private updateNavigation(): void {
        console.debug(`LOCK navigation=${this.isNavigating} invalid=${this.isInvalid}`);
        const isLocked = this.questionUi.getLocked();
        const isReadOnly = this.questionUi.getReadOnly();
        this.prevControl.disabled = this.noPrev || this.isInvalid || this.isNavigating || isLocked || this.isForced;
        this.nextControl.disabled = this.noNext || this.isInvalid || this.isNavigating || isLocked || this.isForced;
        this.logoutControl.disabled = this.isInvalid || this.isNavigating || isLocked;
        this.questionReview.disabled = isLocked;
        this.langControl.disabled = isLocked;
        this.calcControl.disabled = isLocked || isReadOnly;
        if (this.isNavigating || this.isInvalid || this.isForced) {
            for (let i = 0; i < this.overview.length; ++i) {
                const ss = this.overview[i];
                if (i == this.qid) {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.7)';
                        ss[j].questionReview.style.fontWeight = 'bold';
                    }
                } else {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.3)';
                        ss[j].questionReview.style.fontWeight = 'normal';
                    }
                }
            }
        } else {
            let first;
            let last;
            for (let i = 0; i < this.overview.length; ++i) {
                const ss = this.overview[i];
                if (i == this.qid) {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 1)';
                        ss[j].questionReview.style.fontWeight = 'bold';
                        if (ss[j].questionReview.style.display !== 'none') {
                            if (first === undefined) {
                                first = ss[j].questionReview;
                            }
                            last = ss[j].questionReview;
                        }
                    }
                } else {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.7)';
                        ss[j].questionReview.style.fontWeight = 'normal';
                    }
                }
            }
            if (first) {
                scrollRangeIntoView(first, last);
            }
        }
        this.questionUi.setNavigating(this.isNavigating);
    }

    private async findAnsPromise(aid: number): Promise<string> {
        for (let i = 0; i < this.overview.length; ++i) {
            const ss = this.overview[i];
            for (let j = 0; j < ss.length; ++j) {
                if (ss[j].aid === aid) {
                    try {
                        const response = await this.responses.getAnswer(i, j);
                        if (response) {
                            return JSON.stringify(response.answer);
                        } else {
                            return '';
                        }
                    } catch (err) {
                        console.error(err);
                        return '';
                    }
                }
            }
        }
        return '';
    }

    private readonly handleStatus = (i: number, j: number, state: ResponseStatus) => {
        if (j >= 0) {
            this.overview[i][j].setStatus(state);
        }
    }

    private checkSubmitted(): {questions: number, unanswered: number, unsubmitted: number} {
        const questions = this.overview.length;
        let unanswered = 0;
        let unsubmitted = 0;

        for (let qid = 0; qid < questions; ++qid) {
            const ss = this.overview[qid];
            for (let aid = 0; aid < ss.length; ++aid) {
                const qr = ss[aid].questionReview;
                const state = ss[aid].getStatus();
                if (state === ResponseStatus.emptyLocal || state === ResponseStatus.emptyRemote) {
                    if (getComputedStyle(qr).getPropertyValue('display') !== 'none') {
                        ++unanswered;
                    }
                }
                if (state === ResponseStatus.emptyLocal || state === ResponseStatus.savedLocal) {
                    if (getComputedStyle(qr).getPropertyValue('display') !== 'none') {
                        ++unsubmitted;
                    }
                }
            }
        }

        return {questions, unanswered, unsubmitted};
    }

    private showSubmitted({questions, unanswered, unsubmitted}: {questions: number, unanswered: number, unsubmitted: number}, message = ''): void {
        let html = message;
        if (questions > 0) {
            if (unsubmitted > 0) {
                html += translate('FINISH_UNSUBMITTED', {unsubmitted});
            } else {
                html += translate('FINISH_SUBMITTED');
            }

            if (unanswered > 0) {
                html += translate('FINISH_UNANSWERED', {unanswered});
            } else {
                html += translate('FINISH_ANSWERED');
            }
        }
        this.logoutModal.bodyHtml(html);
    }

    public constructor({context, startQuestion, init}: {context: ExamContext, startQuestion: StartQuestion, init: {schedule: VersionedSchedule, elapsed: number, timestamp?: number}}) {
        const {schedule, elapsed, timestamp} = init;
        if (context.content != null) {
            removeChildren(context.content);
        }
        this.contentPanel = mkNode('div', {className: 'content-panel config-background', parent: context.content});
        this.toolBar = mkNode('div', {className: 'tool-bar-vbox config-primary', parent: this.contentPanel});
        this.statusBar = mkNode('div', {className: 'status-bar', parent: this.toolBar});
        this.languageBar = mkNode('div', {className: 'sub-control', parent: this.toolBar});
        this.calculatorBar = mkNode('div', { className: 'calculator-hidden', parent: this.toolBar});
        this.meetingBar = mkNode('div', {className: 'sub-control', parent: this.toolBar});
        //this.controlBar = mkNode('div', {className: 'sub-control', parent: this.toolBar});
        this.notificationArea = mkNode('div', {className: 'message-hidden', parent: this.contentPanel});
        mkNode('div', {className: 'message-container', parent: this.contentPanel, children:[this.notificationArea]});
        this.questionReview = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faListOl }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_OVERVIEW') })
                    ]
                })
            ]
        });
        console.debug('STRUCTURE', context.structure);
        this.questionReview.style.display = (context.structure.reduce((x, y) => x + y.backendAid.length, 0) > 0) ? 'inline-flex' : 'none';
        this.langControl = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faLanguage }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_LANGUAGE') })
                    ]
                })
            ]
        });
        this.langControl.style.display = (context.structure[0]?.backendQid.length > 1) ? 'inline-flex' : 'none';
        this.logoutControl = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faSignOutAlt }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_FINISH') })
                    ]
                })
            ]
        });
        this.calcControl = mkNode('button', {
            className: appButtonClasses, parent: (context.meta.disableCalculator ? undefined : this.statusBar), children: [
                mkNode('icon', { icon: faCalculator }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_CALCULATOR') })
                    ]
                })
            ]
        });
        this.cid = mkNode('span', {className: 'app-text'}),
        this.appCid = mkNode('button', {
            className: appButtonClasses,
            parent: this.statusBar,
            attrib: { disabled: 'true' },
            children: [
                this.cid,
                mkNode('span', { className: 'app-button-text', children: [
                    mkNode('text', { text: translate('CONTROL_USERID') })
                ]}),
            ]
        });
        this.navControls = mkNode('div', { className: 'app-together', parent: this.statusBar });
        mkNode('div', { className: 'app-space', parent: this.navControls });
        this.prevControl = mkNode('button', {
            id: 'prev-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: faArrowLeft }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_PREVIOUS') })
                    ]
                })
            ]
        });
        this.nextControl = mkNode('button', {
            id: 'next-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: faArrowRight }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_NEXT') })
                    ]
                }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_PREVIOUS') })
                    ], attrib: { style: "visibility:hidden; height: 0;" }
                }),
            ]
        });
        this.hPanel = mkNode('div', { className: 'h-panel', parent: this.contentPanel });
        this.reviewPanel = mkNode('div', {
            className: 'review-panel config-background', parent: this.hPanel,
        });
        this.reviewPanel.style.display = (context.structure[0].room !== undefined) ? 'none' : 'block';
        this.reviewInner = mkNode('div', {
            className: 'review-pannel-inner', parent: this.reviewPanel, children: [
                mkNode('div', {
                    className: 'config-primary review-heading', children: [
                        mkNode('text', { text: translate('OVERVIEW_TITLE') })
                    ]
                })
            ]
        });
        this.examPanel = mkNode('div', { className: 'exam-panel config-background', parent: this.hPanel });
        this.logoutModal = new Modal({
            parent: this.contentPanel,
            handler: this.handleModal,
        });
        this.calcText = mkNode('input', {
            className: 'calc-text config-primary config-primary-fg-border config-primary-fg-shadow-focus', parent: this.calculatorBar,
            attrib: { type: 'text', size: '20' },
        });
        this.calcEq = mkNode('input', {
            className: 'calc-button nav-pad-left config-primary config-primary-hover config-primary-fg-border config-primary-fg-shadow-focus', parent: this.calculatorBar,
            attrib: { type: 'button', value: '=' },
        });
        this.accessibility = new Accessibility(this.controlPanel);
        this.robotCat = new RobotCat(this.controlPanel);
        this.qid = startQuestion.question;
        this.currentLanguage = startQuestion.language;
        this.noPrev = false;
        this.noNext = false;
        this.isInvalid = false;
        this.isNavigating = true;
        this.questionUi = new QuestionViewer({
            parent: this.examPanel,
            fullscreenParent: this.contentPanel,
            saveAnswer: async (key: AnswerKey, value: AnswerValue): Promise<void> => {
                try {
                    await context.responses.setAnswerLocal(key, {...value, elapsed: this.examTimer.getElapsed()}, this.handleStatus);
                    this.updateStatus({silent: true}); // async
                } catch (err) {
                    console.error(String(err));
                    alert(String(err));
                }
            },
            loadAnswer: async (qno: number, ano: number): Promise<LocalData|undefined> => {
                return await context.responses.getAnswer(qno, ano);
            },
            setVisible: (qno: number, ano: number, vis: boolean): void => {
                this.overview[qno][ano].setVisible(vis);
            },
            setFlag: async (qno: number, ano: number, flag: boolean): Promise<void> => {
                const ss = this.overview[qno];
                ss[ano].flagged = flag;
                if (flag) {
                    ss[ano].flag.style.visibility = 'visible';
                } else {
                    ss[ano].flag.style.visibility = 'hidden';
                }
                await context.responses.setFlag(qno, ano, flag);
            },
            getFlag: async (qno: number, ano: number): Promise<boolean> => {
                return this.overview[qno][ano].flagged;
            },
            getImageBegin: async (): Promise<void> => await context.imageStore.getImageBegin(),
            getImageFrame: async (image: Img, frame: number): Promise<ArrayBuffer> => {
                const data = await context.imageStore.getImageFrame(image, frame);
                //console.log('EXAM GOT DATA');
                return data.buffer;
            },
            getImageEnd: async (): Promise<void> => await context.imageStore.getImageEnd(),
            setValid: (v: boolean): void => {
                this.isInvalid = !v;
                this.updateNavigation();
            },
            getValid: (): boolean => {
                return !this.isInvalid;
            },
            setNavigating: (v): void => {
                if (v !== undefined) {
                    this.isNavigating = v;
                }
                this.updateNavigation();
            },
            getNavigating: (): boolean => {
                return this.isNavigating;
            },
            getDisplayId: (qno: number, ano: number): string|undefined => {
                return this.overview[qno][ano].displayId;
            },
            controlPanel: this.controlPanel,
            //noMouse: context.noMouse,
            //showQuestionTitle: context.meta.show_question_title ?? false,
            factorDetails: context.factorDetails,
            candidateId: context.candidateId,
            //examId: context.meta.answer_aes_key,
            meetingBar: this.meetingBar,
            //enableCopyPaste: context.meta.enableCopyPaste ?? false,
            //disableResourceLocking: context.meta.disableResourceLocking ?? false,
            meta: context.meta,
            notificationArea: this.notificationArea,
            getTimers: () => this.examTimer,
        });

        this.overview = [];
        const frag = document.createDocumentFragment();
        for (let i = 0; i < context.structure.length; ++i) {
            const elems = [];
            for (let j = 0; j < context.structure[i].length; ++j) {
                const q = new Overview(context.structure[i], i, j, async (qid: number, aid: number) => {
                    if (this.isNavigating || this.isInvalid || this.isForced) {
                        return;
                    }
                    if (qid != null && qid != this.qid) {
                        this.qid = qid;
                        this.isNavigating = true;
                        this.updateNavigation();
                        try {
                            await this.questionUi.setQuestion({
                                structure: this.structure[qid],
                                language: this.currentLanguage,
                                time: this.examTimer.getElapsed(),
                            });
                            await this.examTimer.update();
                        } catch (e) {
                            console.error(e);
                        } finally {
                            this.isNavigating = false;
                            this.noPrev = qid == null || this.qid <= 0;
                            this.noNext = qid == null || this.qid + 1 >= this.structure.length;
                            this.updateNavigation();
                        }
                    }
                    if (aid != null && aid != this.aid) {
                        this.aid = aid;
                    }
                    if (this.aid != null) {
                        this.questionUi.setFocus(this.aid);
                    }
                });
                elems.push(q);
                frag.appendChild(q.questionReview);
            }
            this.overview.push(elems);
        }
        this.reviewInner.appendChild(frag);
        this.reviewPanel.scrollTop = 1;
        this.examPanel.scrollTop = 1;
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.reviewPanel);
        this.resizeObserver.observe(this.examPanel);
        this.updateNavigation();
        if (context.candidateId) {
            this.cid.textContent = context.candidateId;
            this.appCid.style.display = 'inline-flex';
        } else {
            this.appCid.style.display = 'none';
        }
        //this.clockInterval = window.setInterval((): void => {
        //    this.time.textContent = new Date().toLocaleTimeString().toLowerCase();
        //}, 1000);
        this.responses = context.responses;
        this.onDestroy = context.onDestroy;
        this.structure = context.structure;
        this.demo = context.meta.demo ?? false;
        //this.answer_aes_key = context.answer_aes_key;
        this.candidateId = context.candidateId;
        this.examId = context.meta.answer_aes_key;
        window.addEventListener('offline', this.handleOffline);
        window.addEventListener('online', this.handleOnline);
        this.examTimer = new ExamTimer({
            controlPanel: this.controlPanel,
            notificationArea: {
                show: (html: string): void => {
                    this.notificationArea.innerHTML = html;
                    this.notificationArea.className= 'message-warning';
                },
                hide: (): void => {
                    if (this.questionUi?.meetingMessage) {
                        this.notificationArea.innerHTML = this.questionUi.meetingMessage;
                        this.notificationArea.className= 'message-warning';
                    } else {
                        this.notificationArea.className = 'message-hidden';
                    }
                },
                setReadOnly: (isReadOnly: boolean) => {
                    this.questionUi.setReadOnly(isReadOnly);
                    this.calculatorDisabled(isReadOnly);
                },
                setItem: async (q?: number) => {
                    if (this.setItemBusy) {
                        return;
                    }
                    try {
                        this.setItemBusy = true;
                        if (q === undefined) {
                            if (this.isForced) {
                                this.isForced = false;
                                this.updateNavigation();
                            }
                            return;
                        }
                        if (!this.isForced) {
                            this.isForced = true;
                            this.updateNavigation();
                        }
                        if (q !== this.qid) {
                            this.qid = q;
                            this.isNavigating = true;
                            this.updateNavigation();
                            try {
                                await this.questionUi.setQuestion({
                                    structure: this.structure[q],
                                    language: this.currentLanguage,
                                    time: this.examTimer.getElapsed(),
                                });
                            } catch (e) {
                                console.error(e);
                            } finally {
                                this.isNavigating = false;
                                this.noPrev = q == null || q <= 0;
                                this.noNext = q == null || q + 1 >= this.structure.length;
                                this.updateNavigation();
                            }
                        }
                        const meeting = this.questionUi.getMeeting();
                        if (meeting && meeting.getConnected() === ConnectionStatus.Disconnected) {
                            await meeting.startMeeting();
                        }
                    } finally {
                        this.setItemBusy = false;
                    }
                },
                getItem: () => {
                    return this.qid;
                }
            },
            timing: context.meta.timing,
            schedule,
            callback: async () => {
                switch(this.questionUi.getExamType()) {
                    case 'osce':
                        await this.questionUi.getMeeting()?.stopMeeting();
                        this.showFinish();
                        break;
                    case 'written':
                        await this.setState({state: ExamState.Stopped, save: true});
                        try {
                            await this.updateStatus({silent: true, scheduleVersion: Number.MAX_SAFE_INTEGER});
                        } catch (err) {
                            console.error(String(err));
                        }
                        break;
                }
            }
        });
        this.timing = context.meta.timing;
        //console.debug('EXAM_VIEWER ELAPSED', elapsed);
        this.examTimer.setElapsed(elapsed, timestamp);
    }

    private setItemBusy = false;

    private calculatorDisabled(disabled: boolean) {
        this.calcControl.disabled = disabled;
        if (disabled) {
            this.calculatorBar.className = 'calculator-hidden';
        }
    }

    private readonly controlPanel = {
        add: (control: HTMLElement): boolean => {
            if (control.parentElement === this.statusBar) {
                return false;
            } else {
                this.statusBar.insertBefore(control, this.appCid);
                return true;
            }
        },
        remove: (control: HTMLElement): boolean => {
            if (control.parentElement === this.statusBar) {
                this.statusBar.removeChild(control);
                return true;
            } else {
                return false;
            }
        },
        panel: () => {
            return this.toolBar;
        }
    };

    private readonly handleOffline = () => {
        console.log('OFFLINE');
    }

    private readonly handleOnline = async () => {
        console.log('ONLINE');
        try {
            await this.updateStatus({silent: true});
            //await this.responses.resendUnsent(this.handleStatus);
        } catch (err) {
            console.error(String(err));
        }
    }

    private state?: ExamState = undefined;

    private async setState({state, save = false}: {state: ExamState, save?: boolean, autoExit?: boolean}): Promise<void> {
        if (state === this.state) {
            return;
        }
        console.debug('STATE <-', state);
        this.state = state;
        if (save) {
            await dbPut<SavedState>('users', 'state', {state, elapsed: this.examTimer.getElapsed(), timestamp: Date.now()});
        }
        switch (state) {
            case ExamState.Waiting:
            case ExamState.Deleted:
                await this.finish(false);
                break;
            case ExamState.Started:
                this.logoutModal.hide();
                this.logoutModal.reset();
                this.examTimer.start();
                this.questionUi.setLocked(false);
                this.calculatorDisabled(false);
                this.accessibility?.disable(false);
                this.updateNavigation();
                break;
            case ExamState.Paused:
                this.examTimer.stop();
                this.questionUi.setLocked(true);
                this.calculatorDisabled(true);
                this.accessibility?.disable(true);
                this.updateNavigation();
                this.logoutModal.reset();
                this.logoutModal.titleHtml(translate('PAUSED_TITLE'));
                //this.logoutModal.addButtons('navbutton config-safe config-safe-hover config-safe-fg-border config-safe-fg-shadow-focus', {submit: translate('FINISH_SUBMIT')});
                this.showSubmitted(this.checkSubmitted(), translate('PAUSED_DESCRIPTION'));
                this.logoutModal.show();
                break;
            case ExamState.Stopped:
                const check = this.checkSubmitted();
                this.examTimer.stop();
                this.questionUi.setLocked(true);
                this.calculatorDisabled(true);
                this.accessibility?.disable(true);
                this.updateNavigation();
                this.logoutModal.reset();
                this.logoutModal.titleHtml(translate('STOPPED_TITLE'));
                //this.logoutModal.addButtons('navbutton config-safe config-safe-hover config-safe-fg-border config-safe-fg-shadow-focus', {submit: translate('FINISH_SUBMIT')});
                this.logoutModal.addButtons('navbutton config-dngr config-dngr-hover config-dngr-fg-border config-dngr-fg-shadow-focus', {logout: translate('FINISH_NOW')});
                //this.logoutModal.disable(true, 'logout');
                this.showSubmitted(check, translate('FINISH_DESCRIPTION'));
                this.logoutModal.show();
                break;
            default:
                break;
        }
    }

    private readonly handleWaitMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_WAITING', event);
            await this.setState({state: ExamState.Waiting, save: true});
        }
    }

    private readonly handleStartMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_START', event);
            await this.setState({state: ExamState.Started, save: true});
        }
    }

    private readonly handlePauseMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_PAUSE', event);
            await this.setState({state: ExamState.Paused, save: true});
        }
    }

    private readonly handleResumeMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_RESUME', event);
            await this.setState({state: ExamState.Started, save:true});
        }
    }

    private readonly handleStopMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_STOP', event);
            await this.setState({state: ExamState.Stopped, save: true});
        }
    }

    private readonly handleDeleteMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data)) {
            console.log('SSE_DELETE', event);
            await this.setState({state: ExamState.Deleted, save: true});
        }
    }

    private readonly handleTimeMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        console.log('ORIG SSE_TIME', event.data);
        if (isTimingMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_TIME', event.data);
            await this.examTimer.setSchedule({schedule: data.schedule, version: data.scheduleVersion});
        }
    }

    private async statsPromise(context: ExamContext): Promise<void> {
        await context.responses.getStatus(this.handleStatus);
    }

    private async flagsPromise(context: ExamContext): Promise<void> {
        const flags = await context.responses.getFlags();
        for (const flag of flags) {
            //console.log(flag.qid, flag.aid, flag.flag);
            if (flag.flag) {
                const s = this.overview[flag.qid];
                s[flag.aid].flagged = true;
                s[flag.aid].flag.style.visibility = 'visible';
            }
        }
    }

    private async questionPromise(startTime: number): Promise<void> {
        await this.questionUi.setQuestion({
            structure: this.structure[this.qid],
            language: this.currentLanguage,
            time: startTime,
        });
        await this.questionUi.checkpointQuestion(); // save first question timings.
    }

    private async visibilityPromise(): Promise<void> {
        for (const q of this.overview) {
            for (const a of q) {
                a.setVisible(
                    (a.visible == null) ? true : await evalExprBool(a.visible, async n => await this.findAnsPromise(n))
                );
            }
        }
    }

    private resolve?: (value?: unknown) => void;

    public async init({context, state, startTime}: {context: ExamContext, state: ExamState, startTime: number}): Promise<void> {
        try {
            const p1 = this.flagsPromise(context);
            const p2 = this.statsPromise(context);
            const p3 = this.visibilityPromise();
            await p1;
            await this.questionPromise(startTime);
            await p2;
            await p3;
        } catch (e) {
            console.error(e);
        }

        this.isNavigating = false;
        this.noPrev = this.qid == null || this.qid <= 0;
        this.noNext = this.qid == null || this.qid + 1 >= this.structure.length;
        this.updateNavigation();

        const passive: AddEventListenerOptions & EventListenerOptions = {passive: true};
        this.examPanel.addEventListener('scroll', this.handleScroll);
        this.reviewPanel.addEventListener('scroll', this.handleScroll);
        this.reviewInner.addEventListener('click', this.handleJump, passive);
        this.statusBar.addEventListener('click', this.handleToolbar, passive);
        this.calcEq.addEventListener('click', this.handleEq, passive);
        this.calcText.addEventListener('keyup', this.handleText, passive);
        this.eventSource = new ReconnectingEventSource(new URL(urlWithCredentials(`app/${this.examId}/events/`), window.location.origin));
        this.eventSource.addEventListener('exam-pending', this.handleWaitMessage);
        this.eventSource.addEventListener('exam-started', this.handleStartMessage);
        this.eventSource.addEventListener('exam-paused', this.handlePauseMessage);
        this.eventSource.addEventListener('exam-resumed', this.handleResumeMessage);
        this.eventSource.addEventListener('exam-stopped', this.handleStopMessage);
        this.eventSource.addEventListener('exam-deleted', this.handleDeleteMessage);
        this.eventSource.addEventListener('exam-time', this.handleTimeMessage);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        await this.setState({state, save: true});
        await new Promise(resolve => {
            this.resolve = resolve;
        })
    }

    private updating = false;

    private async updateStatus({silent, scheduleVersion = this.examTimer.getScheduleVersion()}: {silent: boolean, scheduleVersion?: number}): Promise<boolean> {
        if (this.updating) {
            return false;
        }
        try {
            this.updating = true;
            while (true) {
                const unsubmitted = await this.responses.getUnsubmitted();

                if (unsubmitted.length === 0) {
                    return true;
                }

                const {remoteStatus} = await this.responses.fetchStatus({
                    state: this.state,
                    elapsed: this.examTimer.getElapsed(),
                    responses: unsubmitted.map(({remote}) => remote),
                    returnResponses: false,
                    scheduleVersion,
                }, silent);

                if (!(remoteStatus || this.demo)) {
                    return false;
                }

                await this.responses.setSubmitted(unsubmitted, this.handleStatus);

                if (remoteStatus?.schedule) {
                    await this.examTimer.setSchedule({schedule: remoteStatus.schedule, version: remoteStatus.scheduleVersion ?? 0});
                }

                if (remoteStatus?.state) {
                    await this.setState({state: remoteStatus.state, save: true});
                }
            }
        } catch(err) {
            console.error(String(err));
            alert(String(err));
            return false;
        } finally {
            this.updating = false;
        }
    }

    private readonly handleScroll = async (event: Event) => {
        if (event.target instanceof Element) {
            await new Promise(resolve => window.requestAnimationFrame(resolve));
            const {scrollTop, scrollLeft, scrollHeight, clientHeight} = event.target
            , atTop = scrollTop === 0
            , beforeTop = 1
            , atBottom = scrollTop === scrollHeight - clientHeight
            , beforeBottom = scrollHeight - clientHeight - 1
            ;
            if (atTop) {
                event.target.scrollTo(scrollLeft, beforeTop);
            } else if (atBottom) {
                event.target.scrollTo(scrollLeft, beforeBottom);
            }
        }
    }

    private readonly handleResize = () => {
        this.reviewPanel.dispatchEvent(new Event('scroll'));
        this.examPanel.dispatchEvent(new Event('scroll'));
    }

    private readonly handleBeforeUnload = async () => {
        await dbPut<SavedState>('users', 'state', {state: this.state ?? ExamState.Waiting, elapsed: this.examTimer.getElapsed(), timestamp: Date.now()});
        await this.questionUi.checkpointQuestion();
        console.log('BEFORE UNLOAD SAVED STATE');
    }

    private showFinish() {
        this.questionUi.setLocked(true);
        this.logoutModal.reset();
        this.logoutModal.titleHtml(translate('FINISH_TITLE'));
        this.logoutModal.addButtons('navbutton config-ntrl config-ntrl-hover config-body-fg-border config-ntrl-fg-border-focus config-body-fg-shadow-focus', {continue: translate('FINISH_CONTINUE')});
        this.logoutModal.addButtons('navbutton config-dngr config-dngr-hover config-body-fg-border config-dngr-fg-border-focus config-body-fg-shadow-focus', {logout: translate('FINISH_NOW')});
        this.logoutModal.show();
        this.showSubmitted(this.checkSubmitted());
    }

    private readonly handleToolbar = async (event: Event): Promise<void> => {
        if (event.target instanceof Node) {
            if (this.questionReview.contains(event.target)) {
                if (window.getComputedStyle(this.reviewPanel).getPropertyValue('display') === 'none') {
                    this.reviewPanel.style.display = 'block';
                    if (this.qid != undefined) {
                        const s = this.overview[this.qid];
                        scrollRangeIntoView(s[0].questionReview, s[s.length - 1].questionReview);
                    }
                } else {
                    this.reviewPanel.style.display = 'none';
                }
                window.dispatchEvent(new Event('resize'));
            } else if (this.langControl.contains(event.target)) {
                if (this.structure[this.qid].backendQid.length > 1) {
                    this.isNavigating = true;
                    this.updateNavigation();
                    try {
                        ++this.currentLanguage;
                        if (this.currentLanguage >= this.structure[this.qid].backendQid.length) {
                            this.currentLanguage = 0;
                        }
                        await dbPut('users', 'language', this.currentLanguage);
                        await this.questionUi.setQuestion({
                            structure: this.structure[this.qid],
                            language: this.currentLanguage,
                            time: this.examTimer.getElapsed(),
                        });
                    } finally {
                        this.isNavigating = false;
                        this.updateNavigation();
                    }
                }
            } else if (this.calcControl.contains(event.target)) {
                if (this.calculatorBar.className === 'calculator-bar') {
                    this.calculatorBar.className = 'calculator-hidden';
                } else {
                    this.calculatorBar.className = 'calculator-bar';
                }
            } else if (this.logoutControl.contains(event.target)) {
                this.showFinish();
            } else if (this.prevControl.contains(event.target)) {
                if (this.qid != null && this.qid > 0) {
                    this.isNavigating = true;
                    this.updateNavigation();
                    --this.qid;
                    try {
                        await this.questionUi.setQuestion({
                            structure: this.structure[this.qid],
                            language: this.currentLanguage,
                            time: this.examTimer.getElapsed(),
                        });
                        await this.examTimer.update();
                    } catch (e) {
                        console.error(e);
                    } finally {
                        this.noPrev = this.qid == null || this.qid <= 0;
                        this.noNext = this.qid == null || this.qid + 1 >= this.structure.length;
                        this.isNavigating = false;
                        this.updateNavigation();
                        this.prevControl.focus();
                    }
                }
            } else if (this.nextControl.contains(event.target)) {
                if (this.qid != null && this.qid + 1 < this.structure.length) {
                    this.isNavigating = true;
                    this.updateNavigation();
                    ++this.qid;
                    try {
                        await this.questionUi.setQuestion({
                            structure: this.structure[this.qid],
                            language: this.currentLanguage,
                            time: this.examTimer.getElapsed(),
                        });
                        await this.examTimer.update();
                    } catch (e) {
                        console.error('handleNext', e);
                    } finally {
                        this.noPrev = this.qid == null || this.qid <= 0;
                        this.noNext = this.qid == null || this.qid + 1 >= this.structure.length;
                        this.isNavigating = false;
                        this.updateNavigation();
                        this.nextControl.focus();
                    }
                }
            }
        }
    }

    private handleEq = (): void => {
        this.calcText.value = math.eval(this.calcText.value || '0');
    }

    private handleText = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            this.calcText.value = math.eval(this.calcText.value || '0');
        }
    }

    private async finish(notify: boolean): Promise<void> {
        console.log('FINISH');
        this.logoutModal.reset();
        this.logoutModal.titleHtml(translate('STOPPED_TITLE'));
        this.logoutModal.bodyHtml(translate('STOPPED_CLEANUP'));
        this.logoutModal.show();
        this.examTimer.stop();
        if (notify) {
            try {
                await this.responses.fetchStatus({state: this.state, elapsed: this.examTimer.getElapsed(), scheduleVersion: Number.MAX_SAFE_INTEGER});
            } catch (err) {
                console.error(String(err));
            }
        }
        //for (const question of this.structure) {
        //    for (const answer of question) {
        //        answer.destroy();
        //    }
        //}
        this.examTimer.destroy();
        if (this.robotCat) {
            this.robotCat.destroy();
        }
        if (this.accessibility) {
            this.accessibility.destroy();
        }
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('offline', this.handleOffline);
        window.removeEventListener('online', this.handleOnline);
        if (this.eventSource) {
            this.eventSource.removeEventListener('exam-time', this.handleTimeMessage);
            this.eventSource.removeEventListener('exam-pending', this.handleWaitMessage);
            this.eventSource.removeEventListener('exam-started', this.handleStartMessage);
            this.eventSource.removeEventListener('exam-paused', this.handlePauseMessage);
            this.eventSource.removeEventListener('exam-resumed', this.handleResumeMessage);
            this.eventSource.removeEventListener('exam-stopped', this.handleStopMessage);
            this.eventSource.removeEventListener('exam-deleted', this.handleDeleteMessage);
            this.eventSource.destroy();
            this.eventSource = undefined;
        }
        const passive: AddEventListenerOptions & EventListenerOptions = {passive: true};
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        this.examPanel.removeEventListener('scroll', this.handleScroll);
        this.reviewPanel.removeEventListener('scroll', this.handleScroll);
        this.reviewInner.removeEventListener('click', this.handleJump, passive);
        this.statusBar.removeEventListener('click', this.handleToolbar, passive);
        this.calcEq.removeEventListener('click', this.handleEq, passive);
        this.calcText.removeEventListener('keyup', this.handleText, passive);
        //clearInterval(this.clockInterval);
        await this.questionUi.setQuestion({
            language: this.currentLanguage,
            time: this.examTimer.getElapsed(),
            notify: false,
        });
        await this.questionUi.destroy();
        examCleanup();
        console.warn('PROMISE', typeof this.onDestroy);
        if (this.onDestroy) {
            await this.onDestroy();
        }
        this.logoutModal.hide();
        this.logoutModal.destroy();
        if (this.resolve) {
            const resolve = this.resolve;
            this.resolve = undefined;
            resolve();
        }
    }

    private handleModal = async (id: string): Promise<void> => {
        switch (id) {
            case 'continue':
                this.questionUi.setLocked(false);
                this.logoutModal.hide();
                break;
            case 'logout':
                if (await this.updateStatus({silent: false})) {
                    this.state = ExamState.Stopped;
                    await this.finish(true);
                } else {
                    this.showSubmitted(this.checkSubmitted());
                }
                break;
            case 'pin':
                const buffer = await pinToKey(this.logoutModal.getValue('pin'));
                const key = btoa(new Uint8Array(buffer).reduce((acc, x) => acc + String.fromCharCode(x), ''));
                console.log('KEY', key);
                break;
            default:
                break;
        }
    }

    private readonly handleJump = async (event: Event): Promise<void> => {
        for (const q of this.overview) {
            for (const a of q) {
                if (event.target instanceof Node && a.questionReview.contains(event.target)) {
                    await a.select();
                    return;
                }
            }
        }
    }
}

/*
function holdingPage(parent: HTMLElement): Promise<void> {
    return new Promise(succ => {
        const holdingPanel = mkNode('div', {
            className: 'login-panel config-background', children: [
                mkNode('div', {
                    className: 'logo-panel', children: [
                        mkNode('img', { className: 'client-logo', attrib: { draggable: 'false', src: '/static/images/client-logo.png' } }),
                    ]
                }),
                mkNode('div', {
                    className: 'login-heading', children: [
                        mkNode('text', { text: 'Do not begin the exam until you are instructed to do so.' }),
                    ]
                }),
            ]
        });
        const holdingButton = mkNode('input', {
            className: 'navigation-primary exam-go', parent: holdingPanel, attrib: {
                type: 'button', value: 'begin'
            }
        });

        console.log('HOLDING CONSTRUCTED');

        holdingButton.onclick = (): void => {
            parent.removeChild(holdingPanel);
            succ();
        };
        parent.appendChild(holdingPanel);

        console.log('HOLDING ADDED');
    });
}
*/

interface StartQuestion {
    question: number;
    language: number;
    time: number;
}

export enum ExamState {
    Waiting = 'WAITING',
    Started = 'STARTED',
    Paused = 'PAUSED',
    Stopped = 'STOPPED',
    Deleted = 'DELETED',
}

interface SavedState {
    state: ExamState;
    elapsed: number;
    //schedule?: PractiqueNet.ExamJson.Definitions.Schedule;
    timestamp: number;
}

export function isState(x: unknown): x is ExamState {
    return typeof x === 'string' &&
    Object.keys(ExamState).reduce((acc: boolean, k: string): boolean => acc || x === (ExamState as {[x:string]:string})[k], false);
}

function isSavedState(x: unknown): x is SavedState {
    if (!isIndexed(x)) {
        console.warn('SavedState is not an object');
        return false;
    }
    if (!isState(x.state)) {
        console.warn('SavedState has invalid State');
        return false;
    }
    if (typeof x.elapsed !== 'number') {
        console.warn('SavedState has invalid elapsed time');
        return false;
    }
    return true;
}

interface InitState {
    state: ExamState;
    elapsed: number;
    schedule: VersionedSchedule;
    timestamp?: number;
    unsubmitted: {
        itemIndex: number;
        fieldIndex: number;
        remote: RemoteData;
    }[];
}

export async function deleteCurrentExam(): Promise<void> {
    let examCount = 0;
    const savedExamCount = await dbGet('users', 'examCount');
    if (typeof savedExamCount === 'number') {
        examCount = savedExamCount;
    }
    await dbPut('users', 'examCount', examCount + 1);
    await dbClearSelect();
}

export async function cleanUpOldExam(context: ExamCxt): Promise<InitState> {
    const responses = new ResponseModel(context.meta.answer_aes_key, context.candidateId, context.meta.demo ?? false, context.structure);

    const savedSchedule = await dbGet('users', 'schedule');
    let schedule: VersionedSchedule = {schedule: context.meta.timing?.schedule ?? [], version: 0};
    if (savedSchedule && isVersionedSchedule(savedSchedule)) {
        schedule = savedSchedule;
    }

    const savedState = await dbGet('users', 'state');
    let elapsed = 0;
    let timestamp = Date.now();
    let state = ExamState.Started;
    if (savedState && isSavedState(savedState)) {
        console.debug('SAVED_STATUS', savedState);
        state = savedState.state ?? state;
        timestamp = savedState.timestamp;
        elapsed = savedState.elapsed;
    }

    const unsubmitted = await responses.getUnsubmitted();

    const {remoteStatus, timestamp: fetchTimestamp} = await responses.fetchStatus({
        state,
        elapsed,
        responses: unsubmitted.map(({remote}) => remote),
        returnResponses: true,
        scheduleVersion: schedule.version,
    });

    if (remoteStatus) {
        console.debug('REMOTE_STATUS', remoteStatus)

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await responses.setSubmitted(unsubmitted, () => {});

        state = remoteStatus.state ?? state;
        if (remoteStatus.elapsed !== undefined) {
            elapsed = remoteStatus.elapsed;
        }

        if (fetchTimestamp !== undefined) {
            timestamp = fetchTimestamp;
        }

        if (remoteStatus.schedule) {
            schedule = {schedule: remoteStatus.schedule, version: remoteStatus.scheduleVersion || 0};
        }

        await responses.updateResponses(remoteStatus);
    }

    return {state, elapsed, schedule, timestamp, unsubmitted};
}

export async function runExam(state: InitState, context: ExamCxt): Promise<void> {
    console.debug('RUN EXAM');
    const responses = new ResponseModel(context.meta.answer_aes_key, context.candidateId, context.meta.demo ?? false, context.structure);
    const startQuestion: StartQuestion = {
        question: 0,
        language: 0,
        time: state.elapsed,
    }
    const savedLanguage = await dbGet('users', 'language');
    if (typeof savedLanguage === 'number') {
        startQuestion.language = savedLanguage;
    }
    const lastQuestion = await responses.getLastQuestion();
    if (lastQuestion) {
        console.log('LAST_QUESTION', lastQuestion);
        startQuestion.time = lastQuestion.elapsed;
        if (lastQuestion.nextQuestion !== undefined) {
            startQuestion.question = lastQuestion.nextQuestion;
        }
    }
    const cxt: ExamContext = {
        ...context,
        responses: responses,
        onDestroy: async () => {
            console.warn('*** onDestroy CALLED ***');
            await deleteCurrentExam();
            await context.onDestroy();
        },
    }
    const xv = new ExamViewer({context: cxt, startQuestion, init: state});
    await xv.init({context: cxt, state: state.state, startTime: startQuestion.time});
}
