import { Transform } from './utils';

export interface RendererFactory {
    name: string;
    isThis(resource: Img): boolean;
    hasMime(mime: string): boolean;
    makeImg(id: string, buffer: ArrayBuffer): Img;
    addImg?(img: Img, buffer: ArrayBuffer): Img;
    makeRenderer(resource: Img): Renderer;
}

const rendererTypes: RendererFactory[] = [];

export function forEachRendererType(f: (rf: RendererFactory) => void): void {
    rendererTypes.forEach(f);
}

export function registerRendererType(rf: RendererFactory): void {
    console.info('REGISTER_RENDERER', rf.name)
    rendererTypes.push(rf);
}

export function makeRenderer(mime: string, buffer: ArrayBuffer, id = ''): Renderer|undefined {
    for (const rendererType of rendererTypes) {
        console.log(`TRY ${rendererType.name} ON ${mime}`);
        if (rendererType.hasMime(mime)) {
            console.log('BUFFER', buffer);
            const img = rendererType.makeImg(id, buffer);
            console.log('SUCCESS');
            return rendererType.makeRenderer(img);
        } else {
            console.log('FAILED');
        }
    }
    return;
}

export function rendererFromImage(image: Img): Renderer|undefined {
    for (const rendererType of rendererTypes) {
        console.log('TRY', rendererType.name);
        if (rendererType.isThis(image)) {
            console.log('SUCCESS');
            return rendererType.makeRenderer(image);
        }
    }
    return;
}

export enum IType {
    Unknown,
    Dicom,
    Tiff,
    Std,
    Video,
    Audio,
    Pdf,
    J2k,
}

export interface Img {
    id: string;
    iType: IType;
    data: (ArrayBuffer|null)[];
    dataSize: number[];
    frames: number;
    caption? : string;
    dbOffset? : number;
    rows?: number;
    cols?: number;
    mime?: string;
    distribution?: 'all' | 'restricted';
}

export interface Renderer {
    img: Img;
    index: number;
    render(): Promise<void>;
    renderThumbnail(): Promise<ImageData>;
    convexMean({y0, y1, f}: {y0: number, y1: number, f: (y: number) => {x0: number, x1: number}}): {mean?: number, stddev?: number};
    animationFrame(context: CanvasRenderingContext2D, base: Transform): Promise<void>;
    load(
        begin: () => Promise<void>,
        frame: (image: Img, frame: number) => Promise<ArrayBuffer>,
        end: () => Promise<void>,
        render: () => Promise<void>,
        progress: (p: number) => void
    ): Promise<void>;
    destroy(): void;
}

