import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Français', {
    LOCALE: 'fr-CA',
    LOGIN_CANDIDATE_ID_LABEL: 'ID',
    LOGIN_CANDIDATE_ID_HINT: 'N\u1d52 d’identification',
    LOGIN_PASSWORD_LABEL: 'MDP',
    LOGIN_PASSWORD_HINT: 'Mot de passe',
    LOGIN_BUTTON: 'Connexion',
    
    CHOOSE_INSTRUCTIONS: ({cid}) => `${cid ? `<p>Vous avez ouvert une session en tant que candidat(e) <b>${cid}</b>.</p>` : ''}`,
    CHOOSE_PROCTORED_EXAMITY: `<p><b>INSTRUCTIONS</b></p><p>Cliquez sur <i>Démarrer&nbsp;la&nbsp;surveillance</i> pour accéder à la plateforme EXAMITY<ul><li>Pour créer votre profil</li><li>Pour vous connecter au surveillant et commencer l’examen</li><li>Veuillez attendre si vous êtes redirigé ici et que l’examen n’a pas encore commencé; la connexion se fera automatiquement au début de l’examen.</li></ul><p>`,
    CHOOSE_AUTO_START: `<p><b>INSTRUCTIONS</b></p><p>Veuillez attendre; la connexion se fera automatiquement au début de l’examen.</p>`,
    CHOOSE_AUTO_SUPPORT: `<p><b>SOUTIEN</b></p><p>Si la connexion n’est pas établie après le début de l’examen, communiquez avec l’équipe de soutien aux examens.</p>`,
    CHOOSE_PIN_LABEL: 'NIP',
    CHOOSE_AUTO_PIN_HINT: 'NIP démarrage hors ligne',
    CHOOSE_EXAM_BUTTON: 'Commencer l’examen sélectionné',
    CHOOSE_LOGOUT_BUTTON: 'Fermer la session',
    CHOOSE_PROCTOR_BUTTON: 'Démarrer la suveillance',
    CHOOSE_EXAM_HINT: 'Sélectionner l’examen…',

    ERROR_NETWORK: 'Erreur réseau',
    ERROR_USER_PASS: 'Le n<sup>o</sup> d’identification ou le mot de passe est incorrect',
    ERROR_CONNECTION_TIMEOUT: 'Délai d’attente de connexion',
    ERROR_HTTP: ({code}) => `Erreur de serveur ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_UNKNOWN: ({message}) => `Erreur ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "L’espace de stockage est saturé. Libérez de l’espace ou utilisez un autre ordinateur.",
    ERROR_PIN: 'Le NIP ou l’examen sélectionné est incorrect',
    ERROR_CANDIDATE_NOT_FOUND: 'Le n<sup>o</sup> d’identification est incorrect ou n’est pas inscrit pour cet examen. ',

    DOWNLOAD_TITLE: ({downloadCount}) => `Téléchargement de ${downloadCount} examen${plural(downloadCount) ? 's' : ''}, veuillez attendre…`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Le téléchargement peut prendre plusieurs minutes en fonction de votre connectivité réseau.</p>${freeSpace ? `<p>[Il reste <b>${freeSpace}Mo</b> d’espace de stockage gratuit]</p>` : ''}`,

    PREPARING_TITLE: 'Préparation de l’examen, veuillez attendre…',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>La préparation peut prendre plusieurs minutes selon les spécifications de votre ordinateur.</p>${freeSpace ? `<p>[Il reste <b>${freeSpace}Mo</b> d’espace de stockage gratuit]</p>` : ''}`,
    
    CONTROL_OVERVIEW: 'APERÇU',
    CONTROL_LANGUAGE: 'LANGUE',
    CONTROL_FINISH: 'TERMINER',
    CONTROL_CALCULATOR: 'CALCULATRICE',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'ID UTILILSATEUR',
    CONTROL_PREVIOUS: 'PRÉCÉDENTE',
    CONTROL_NEXT: 'SUIVANTE',
    CONTROL_COLOUR: 'COULEUR',

    NOTIFICATION_CONNECT: '<center>Click the <b>CONNECT</b> button above to start.</center>',
    NO_CANDIDATE: 'No candidate for this round.',

    MEETING_POOR_CONNECTION: '<center><b>Poor connection</b> you may have audio and video quality issues.</center>',
    MEETING_STATUS_ERROR: ({err}) => `<center><b>Meeting exit status</b> ${err}</center>`,

    TIMER_REMAINING: 'TEMPS RESTANT',
    TIMER_ELAPSED: 'TEMPS ÉCOULÉ',

    OVERVIEW_TITLE: 'Aperçu',
    RESOURCE_RESTRICTED_SHOW: 'Show to candidate',
    RESOURCE_RESTRICTED_HIDE: 'Hide from candidate',

    ANSWER_TITLE: 'Réponse',
    ANSWER_FLAG_TIP: 'Marquer cette question',
    SBA_ANSWER_TIP: 'Sélectionner cette réponse',
    SBA_ELIMINATE_TIP: 'Éliminer cette réponse',

    PAUSED_TITLE: 'Examen mis en pause',
    PAUSED_DESCRIPTION: '<p>Un administrateur <strong>a mis l’examen en pause</strong> à distance.<p>',

    STOPPED_TITLE: 'Examen terminé',
    STOPPED_CLEANUP: '<p>Nettoyage en cours; veuillez attendre...<p>',

    FINISH_TITLE: 'Vous avez vraiment terminé?',
    FINISH_DESCRIPTION: 'Appuyez sur <i>Terminer maintenant</i> avant de fermer l’onglet ou la fenêtre.</p>',
    FINISH_ANSWERED: '<p>Vous avez répondu à toutes les questions.</p>',
    FINISH_UNANSWERED: ({unanswered}) => `<p>Il reste <strong>${unanswered}</strong> question${plural(unanswered) ? 's' : ''} sans réponse..</p>`,
    FINISH_SUBMITTED: '<p><b>Toutes vos réponses ont été envoyées au serveur.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Il reste <strong>${unsubmitted}</strong> réponse${plural(unsubmitted) ? 's' : ''} à envoyer au serveur.</p><p>Vous pouvez poursuivre l’examen; les réponses seront enregistrées dans votre ordinateur.</p><p>Les réponses non envoyées sont causées par des problèmes de réseau; vérifiez si le vôtre fonctionne et réessayez avec le bouton ci-dessous. Vous devez soumettre ces réponses avant de pouvoir terminer l’examen. Communiquez avec l’équipe de soutien aux examens au besoin.</p>`,
    FINISH_CONTINUE: 'Poursuivre l’examen',
    FINISH_NOW: 'Soumettre l’examen',
});
