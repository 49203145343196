import { createCanvas, CanvasRenderingContext2D } from 'canvas';
import pdfjs from 'pdfjs-dist';
import { Img, IType, Renderer, registerRendererType } from 'image-base';
import { Transform } from 'utils';
//import pdfWorker from 'pdfjs-dist/es5/build/pdf.worker.js';

//const blob = new Blob([pdfWorker]);
//pdfjs.GlobalWorkerOptions.workerSrc = URL.createObjectURL(blob);

pdfjs.GlobalWorkerOptions.workerSrc = 'libs/pdf.worker.js';
let worker: pdfjs.PDFWorker|null = null;

function createWorker(): pdfjs.PDFWorker {
    //const blob = new Blob([pdfWorker]);
    //pdfjs.GlobalWorkerOptions.workerSrc = URL.createObjectURL(blob);
    const worker = new pdfjs.PDFWorker({name: 'pdfjs worker'});
    //URL.revokeObjectURL(pdfjs.GlobalWorkerOptions.workerSrc);
    //pdfjs.GlobalWorkerOptions.workerSrc = '';
    return worker;
}

/*
declare global {
    interface PromiseConstructor {
        allSettled<T>(promises: Promise<T>[]): Promise<(
            {status: string; value: T} | {status: string; reason: string}
        )[]>;
    }
}

Promise.allSettled = <T>(promises: Promise<T>[]): Promise<({status: string; value: T} | {status: string; reason: string})[]> => Promise.all(promises.map(async (promise) => {
    try {
        const value = await promise;
        return {
            status: 'fulfilled',
            value: value,
        };
    }
    catch (reason) {
        return {
            status: 'rejected',
            reason: reason,
        };
    }
}));
*/

export interface Pdf extends Img {}

export type Type = Pdf;

export function makePdf(id: string, data: ArrayBuffer[]): Pdf {
    return {
        id,
        iType: IType.Pdf,
        data,
        dataSize: data.map((buf: ArrayBuffer): number => buf.byteLength),
        frames: data.length,
    }
}

export function isPdf(x: Img): x is Pdf {
    return x.iType === IType.Pdf;
}

declare module 'pdfjs-dist' {
    export interface PDFRenderParams {
        transform?: number[];
        background?: string;
    }
    //export interface ViewportParameters {
    //    offsetX?: number;
    //    offsetY?: number;
    //}
}

export class PdfRenderer implements Renderer {
    public index: number;
    public readonly img: Pdf;
    private pdf?: pdfjs.PDFDocumentProxy;
    private page?: pdfjs.PDFPageProxy;
    private viewport?: pdfjs.PDFPageViewport;

    public constructor(img: Pdf) {
        this.img = img;
        this.index = 0;
    }

    public destroy(): void {
        //if (this.page) {
        //    this.page.destroy();
        //}
        if (this.pdf) {
            this.pdf.destroy();
        }
        if (worker) {
            worker.destroy()
            worker = null;
        }
        //this.img.data = [];
    }

    private async getDimensions(pdf: pdfjs.PDFDocumentProxy): Promise<void> {
        this.page = await pdf.getPage(this.index + 1);
        this.viewport = this.page.getViewport({scale: 1});
        this.img.cols = this.viewport.width;
        this.img.rows = this.viewport.height;
    }

    public async render(): Promise<void> {
        if (this.pdf) {
            this.page = await this.pdf.getPage(this.index + 1);
            this.viewport = this.page.getViewport({scale: 1});
            this.img.cols = this.viewport.width;
            this.img.rows = this.viewport.height;
        }
    }

    public async renderThumbnail(): Promise<ImageData> {
        const data = this.img.data[0];
        if (!data) {
            throw 'pdf is not loaded';
        }
        if (!worker) {
            worker = createWorker();
        }
        const pdf = await pdfjs.getDocument({
            worker: worker,
            data: data,
        }).promise;
        const page = await pdf.getPage(1);
        const vp = page.getViewport({scale: 1});
        this.img.cols = vp.width;
        this.img.rows = vp.height;
        this.img.frames = pdf.numPages;
        const cols = 120 * vp.width / vp.height;
        const rows = 120;
        const scale = Math.min(cols / vp.width, rows / vp.height);
        const canvas = createCanvas(cols, rows);
        const cxt = canvas.getContext('2d', { alpha: false });
        if (cxt == null) {
            throw 'visible CONTEXT is null';
        }
        cxt.imageSmoothingEnabled = true;
        cxt.imageSmoothingQuality = 'high';
        await page.render({
            canvasContext: cxt,
            viewport: page.getViewport({scale: scale})
        }).promise;
        return  cxt.getImageData(0, 0, canvas.width, canvas.height);
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        if (this.page && this.viewport && this.img.cols && this.img.rows) {
            const canvas = createCanvas(context.canvas.width, context.canvas.height);
            const cxt = canvas.getContext('2d', {alpha: false});
            cxt.fillStyle = 'black';
            cxt.fillRect(0, 0, canvas.width, canvas.height);
            //t = t.multiplyBy(Transform.identity.translateBy((canvas.width - this.viewport.width) / 2.0, (canvas.height - this.viewport.height) / 2.0));
            cxt.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            cxt.fillStyle = 'white';
            cxt.fillRect(0, 0, this.viewport.width, this.viewport.height);
            cxt.imageSmoothingEnabled = true;
            cxt.imageSmoothingQuality = 'high';
            await this.page.render({
                canvasContext: cxt,
                background: 'rgba(0,0,0,0)',
                //transform: [t.s, t.r, -t.r, t.s, t.tx, t.ty],
                viewport: this.viewport,
            }).promise;
            context.drawImage(canvas, 0, 0);
        }
    }

    public async load(
        begin: () => Promise<void>,
        frame: (image: Img, frame: number) => Promise<ArrayBuffer>, 
        end: () => Promise<void>,
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        await begin();
        if (!worker) {
            worker = createWorker();
        }
        const data = await frame(this.img, 0);
        this.pdf = await pdfjs.getDocument({
            worker: worker,
            data: data,
        }).promise;
        await this.getDimensions(this.pdf);
        await render();
        progress(200.0);
        await end();
    }

    public convexMean(): {mean?: number, stddev?: number} {
        return {};
    }
}

export function isPdfRenderer(renderer: Renderer): renderer is PdfRenderer {
    return isPdf(renderer.img);
}

registerRendererType({
    name: 'PdfRenderer',
    hasMime(mime: string): boolean {
        return mime === 'application/pdf';  
    },
    makeImg(id: string, buffer: ArrayBuffer): Img {
        return makePdf(id, [buffer]);
    },
    isThis(resource: Img) {
        return resource.iType === IType.Pdf;
    },
    makeRenderer(resource: Pdf): Renderer {
        return new PdfRenderer(resource);
    }
});
