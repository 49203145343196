import { translate } from 'utils-lang';

declare global {
    interface Window {
        webkitIndexedDB?: typeof window.indexedDB;
        mozIndexedDB?: typeof window.indexedDB;
        oIndexedDB?: typeof window.indexedDB;
        msIndexedDB?: typeof window.indexedDB;
        shimIndexedDB?: typeof window.indexedDB;
    }
}

const IDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB ||
    window.oIndexedDB || window.msIndexedDB || window.shimIndexedDB;

if (IDB === window.shimIndexedDB) {
    console.warn('WARNING: using IndexedDBShim');
}

export class StorageError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'StorageError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
} 

let db: IDBDatabase | undefined = undefined;

//export function closeDatabase(): void {
//    db?.close();
//    db = undefined;
//}

export async function openDatabase(): Promise<IDBDatabase> {
    if (db) {
        return db;
    }
    return new Promise((succ, fail): void => {
        try {
            const openRequest = IDB.open('practique', 6);
            openRequest.onblocked = (): void => {
                console.log('open database blocked:', String(openRequest.error));
                fail(new StorageError(String(openRequest.error)));
            };
            openRequest.onerror = (): void => {
                console.log('open database error: ', String(openRequest.error));
                fail(new StorageError(String(openRequest.error)));
            };
            openRequest.onupgradeneeded = (event: IDBVersionChangeEvent): void => {
                console.log('upgrade needed');
                if (event.newVersion != null && event.oldVersion < event.newVersion) {
                    console.log('upgrading database');
                    const db = openRequest.result;
                    if (db.objectStoreNames.contains('exams')) {
                        db.deleteObjectStore("exams");
                    }
                    if (db.objectStoreNames.contains('encrypted')) {
                        db.deleteObjectStore("encrypted");
                    }
                    if (db.objectStoreNames.contains('questions')) {
                        db.deleteObjectStore('questions');
                    }
                    if (db.objectStoreNames.contains('images')) {
                        db.deleteObjectStore('images');
                    }
                    if (db.objectStoreNames.contains('answers')) {
                        db.deleteObjectStore('answers');
                    }
                    if (db.objectStoreNames.contains('status')) {
                        db.deleteObjectStore('status');
                    }
                    if (db.objectStoreNames.contains('flags')) {
                        db.deleteObjectStore('flags');
                    }
                    if (db.objectStoreNames.contains('users')) {
                        db.deleteObjectStore('users');
                    }
                    if (db.objectStoreNames.contains('session')) {
                        db.deleteObjectStore('session');
                    }
                    db.createObjectStore('exams');
                    db.createObjectStore('encrypted');
                    db.createObjectStore('questions');
                    db.createObjectStore('images');
                    db.createObjectStore('answers');
                    db.createObjectStore('status');
                    db.createObjectStore('flags');
                    db.createObjectStore('users');
                    db.createObjectStore('session');
                } else {
                    throw 'A newer version of the database exists on this device, clear web storage to continue';
                }
            };
            openRequest.onsuccess = (): void => {
                db = openRequest.result;
                db.onversionchange = () => {
                    console.log('DB_VERSION_CHANGE');
                    if (db) {
                        db.close();
                        db = undefined;
                    }
                }
                db.onclose = () => {
                    console.log('DB_CLOSED');
                    db = undefined;
                }
                succ(db);
            };
        } catch (err) {
            console.error('error: openDatabase', String(err));
            fail(new StorageError(String(err)));
        }
    });
}

export async function dbGet<T = unknown>(store: string, key: IDBValidKey | IDBKeyRange): Promise<T | undefined> {
    const db = await openDatabase();
    return new Promise<T>((resolve, reject): void => {
        const request = db.transaction([store], "readonly").objectStore(store).get(key);
        request.onerror = (): void => {
            reject(new StorageError(String(request.error)));
        };
        request.onsuccess = (): void => {
            resolve(request.result);
        }
    });
}

export async function dbCursor(store: string, key: undefined | IDBValidKey | IDBKeyRange,
    fn: (cursor: IDBCursorWithValue) => void
): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const request = db.transaction([store], "readonly").objectStore(store).openCursor(key);
        request.onerror = (): void => {
            fail(new StorageError(String(request.error)));
        };
        request.onsuccess = (): void => {
            const cursor = request.result;
            if (cursor) {
                try {
                    fn(cursor);
                    cursor['continue']();
                } catch (err) {
                    fail(err);
                }
            } else {
                succ();
            }
        };
    });
}

export async function dbPut<T>(store: string, key: IDBValidKey, value: T): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>(async (succ, fail): Promise<void> => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).put(value, key);
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(String(transaction.error)));
        };
        transaction.oncomplete = (): void => {
            succ();
        };
    });
}

export async function dbPutList<T>(store: string, list: {key: IDBValidKey, value: T}[]): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail) => {
        const transaction = db.transaction([store], "readwrite");
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(String(transaction.error)));
        };
        transaction.oncomplete = (): void => {
            succ();
        };
        let i = 0;
        const next = () => {
            if (i < list.length) {
                const request = transaction.objectStore(store).put(list[i].value, list[i].key);
                request.onsuccess = next;
                ++i;
            }
        }
        next();
    });
}

export async function dbDelete(store: string, key: IDBValidKey | IDBKeyRange): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).delete(key);
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(String(transaction.error)));
        };
        transaction.oncomplete = (): void => {
            succ();
        };
    });
}

export async function dbClear(store: string): Promise<void> {
    const db = await openDatabase();
    return new Promise<void>((succ, fail): void => {
        const transaction = db.transaction([store], "readwrite");
        transaction.objectStore(store).clear();
        transaction.onabort = (): void => {
            fail(new StorageError(translate('ERROR_STORAGE')));
        };
        transaction.onerror = (): void => {
            fail(new StorageError(String(transaction.error)));
        };
        transaction.oncomplete = (): void => {
            succ();
        }
    });
}

export async function dbClearLogin(): Promise<void> {
    console.log("DELETE_ALL_BEGIN");
    const t0 = performance.now();
    await dbClear('exams');
    await dbClear('encrypted');
    await dbClear('questions');
    await dbClear('images');
    await dbClear('answers');
    await dbClear('status');
    await dbClear('flags');
    await dbClear('users');
    const t1 = performance.now();
    console.log(`DELETE_ALL_END ${(t1 - t0)/1000}`);
}

export async function dbClearSelect(): Promise<void> {
    console.log("DELETE_EXAM_BEGIN");
    const t0 = performance.now();
    await dbClear('questions');
    await dbClear('images');
    await dbClear('answers');
    await dbClear('status');
    await dbClear('flags');
    await dbDelete('users', 'language');
    await dbDelete('users', 'question');
    await dbDelete('users', 'state');
    await dbDelete('users', 'schedule');
    await dbDelete('users', 'scheduleVersion');
    await dbDelete('users', 'manifest');
    await dbDelete('users', 'exam');
    const t1 = performance.now();
    console.log(`DELETE_EXAM_END ${(t1 - t0)/1000}`);
}
